
import { mapState } from 'vuex'
export default {
  name: 'DefaultLayout',
  components: {
    primaryNavigation: () => import('@/components/layouts/navbar.vue'),
    sideBar: () => import('@/components/layouts/sidebar.vue'),
    sideBarLogin: () => import('@/components/layouts/sidebarLogin.vue'),
    sideBarProfile: () => import('@/components/layouts/sidebarProfile.vue'),
    contentLoader: () =>
      import('@/components/sections/global/contentLoader.vue'),
    PrimaryFooter: () => import('@/components/layouts/footer.vue'),
    footerBottom: () => import('@/components/layouts/footerbottom.vue'),
    navbarResponsive: () => import('@/components/layouts/navbarResponsive.vue'),
    FollowerCursor: () => import('@/components/general/FollowerCursor.vue'),
    notificationModal: () => import('@/components/sections/global/notification'),
  },
  data() {
    return {
      metaData: null,
      activeLang: null,
    }
  },
  async fetch() {
    const route = this.$route.path
    await this.$store
      .dispatch('getContentDetails', {
        url: route,
      })
      .then((res) => {
        this.metaData = res?.page
        this.activeLang = res?.languages?.find((obj) => obj.active === 1)
      })
      .catch((err) => {
        return err
      })
  },
  // fetchOnServer: false,
  head() {
    return {
      title:
        this.metaData && this.metaData.title
          ? `RheinWelten - ${this.metaData.title}`
          : 'RheinWelten - Den Rhein per E-Bike entdecken.',
      htmlAttrs: {
        lang:
          this.activeLang && this.activeLang.twoLetterIsoCode
            ? this.activeLang.twoLetterIsoCode
            : 'de',
      },
      meta: [
        {
          name: 'description',
          hid: 'description',
          content:
            this.metaData && this.metaData.meta
              ? this.metaData.meta.description
              : 'RheinWelten verläuft vom Oberalppass bis zur Grenze in Basel entlang des Rheins. In 14 Erlebniswelten entdecken Reisebegeisterte mit dem E-Bike regionale, kulinarische und kulturelle Highlights. Jetzt entdecken ✓',
        },
        {
          name: 'language',
          content:
            this.activeLang && this.activeLang.twoLetterIsoCode
              ? this.activeLang.twoLetterIsoCode
              : 'de',
        },
        {
          name: 'image',
          hid: 'image',
          content:
            this.metaData &&
            this.metaData.socialMedia &&
            this.metaData.socialMedia.ogImage.length > 0
              ? this.metaData.socialMedia.ogImage[0].publicUrl.toString()
              : '',
        },
        /* Open Graph */
        {
          name: 'og:title',
          hid: 'og:title',
          content:
            this.metaData && this.metaData.socialMedia
              ? this.metaData.socialMedia.ogTitle
              : 'RheinWelten - Den Rhein per E-Bike entdecken.',
        },
        {
          name: 'og:site_name',
          hid: 'og:site_name',
          content: 'Rheinwelten',
        },
        {
          name: 'og:url',
          hid: 'og:url',
          content: this.redirectUrl || 'https://www.rheinwelten.com/',
        },
        {
          name: 'og:description',
          hid: 'og:description',
          content:
            this.metaData && this.metaData.socialMedia
              ? this.metaData.socialMedia.ogDescription
              : 'RheinWelten verläuft vom Oberalppass bis zur Grenze in Basel entlang des Rheins. In 14 Erlebniswelten entdecken Reisebegeisterte mit dem E-Bike regionale, kulinarische und kulturelle Highlights. Jetzt entdecken ✓',
        },
        {
          name: 'og:type',
          hid: 'og:type',
          content: 'website',
        },
        {
          name: 'og:image',
          hid: 'og:image',
          content:
            this.metaData &&
            this.metaData.socialMedia &&
            this.metaData.socialMedia.ogImage.length > 0
              ? this.metaData.socialMedia.ogImage[0].publicUrl
              : '',
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '630',
        },
        /* Twitter */
        {
          name: 'twitter:card',
          hid: 'twitter:card',
          content:
            this.metaData && this.metaData.socialMedia
              ? this.metaData.socialMedia.twitterCard
              : '',
        },
        { property: 'twitter:site', content: '' },
        {
          name: 'twitter:title',
          hid: 'twitter:title',
          content:
            this.metaData && this.metaData.socialMedia
              ? this.metaData.socialMedia.twitterTitle
              : 'RheinWelten - Den Rhein per E-Bike entdecken.',
        },
        {
          name: 'twitter:description',
          hid: 'twitter:description',
          content:
            this.metaData && this.metaData.socialMedia
              ? this.metaData.socialMedia.twitterDescription
              : 'RheinWelten verläuft vom Oberalppass bis zur Grenze in Basel entlang des Rheins. In 14 Erlebniswelten entdecken Reisebegeisterte mit dem E-Bike regionale, kulinarische und kulturelle Highlights. Jetzt entdecken ✓',
        },
        {
          property: 'twitter:url',
          content: '',
        },
        {
          name: 'twitter:image',
          hid: 'twitter:image',
          content:
            this.metaData &&
            this.metaData.socialMedia &&
            this.metaData.socialMedia.twitterImage.length > 0
              ? this.metaData.socialMedia.twitterImage[0].publicUrl
              : '',
        },
        {
          hid: 'robots',
          name: 'robots',
          content:
            this.metaData &&
            this.metaData.meta.robots &&
            this.metaData.meta.robots.noIndex
              ? 'noindex, nofollow'
              : 'index, follow',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
      menuOpened: (state) => state.isMenuOpened,
      showNotification: (state) => state.showNotification,
    }),
  },
  watch: {
    isLoading: {
      handler() {
        if (this.menuOpened) {
          document.body.classList.add('js-kill-scroll')
        } else {
          document.body.classList.remove('js-kill-scroll')
        }
      },
    },
  },
  async beforeCreate() {
    this.$store.commit('SET_GLOBAL_LOADING', true)
    await this.$store.dispatch('getLinks')
  },
}
